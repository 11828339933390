const en = {
  common: {
    or: 'or',
    cancel: 'Cancel',
    reset: 'Reset',
    save: 'Save',
    submit: 'Submit',
    next: 'Next',
    previous: 'Previous',
    enable: 'Enable',
    disable: 'Disable',
    addTeamMember: 'Add Team Member',
    addProjectManager: 'Add Project Manager',
    saveDraft: 'Save Draft',
    search: 'Search',
    edit: 'Edit',
    remove: 'Remove',
    new: 'New',
    export: 'Export to Excel',
    noDataToExport: 'No data to export',
    import: 'Import',
    discard: 'Discard',
    yes: 'Yes',
    no: 'No',
    pause: 'Pause',
    areYouSure: 'Are you sure?',
    view: 'View',
    destroy: 'Delete',
    mustSelectARow: 'Must select a row',
    confirm: 'Confirm',
    requestRelease: 'Request Release',
    revise: 'Revise',
    release: 'Release',
    reject: 'Reject',
    process: 'Process',
    actions: 'Actions',
    notifyFundingAgencies: 'Notify Funding Agencies',
    notifyVrResearch: 'Notify VR-Research',
    requestContractsReview: 'Request Contracts Review',
    requestProjectNoAssignmentByAccounting: 'Request assignment by Accounting',
    closeProjectNoFunding: 'Close Project - No Funding',
    finish: 'Finish',
    newProjectBtn: 'New project',
    editProjectBtn: 'Edit project',
    changePassword: 'Change password',
    updatePassword: 'Update password',
    password: 'Password',
    approve: 'Approve',
    propose: 'Propose',
    addCooperationPartner: 'Add Cooperation Contract Draft',
    addEmploymentContract: 'Add Employment Contract Draft',
    addWorkPackage: 'Add Work Package',
    endProject: 'End project',
    finishProject: 'Finish project',
    discardFormConfirm: 'Are you sure? Any unsaved data will be lost.',
  },

  app: {
    title: 'Project Management',
  },

  entities: {
    project: {
      name: 'project',
      label: 'Projects',
      menu: 'Projects',
      exporterFileName: 'project_export',
      list: {
        menu: 'Projects',
        title: 'Projects',
      },
      create: {
        success: 'Project saved successfully',
      },
      update: {
        success: 'Project updated successfully',
      },
      destroy: {
        success: 'Project deleted successfully',
      },
      destroyAll: {
        success: 'Project(s) deleted successfully',
      },
      createNote: {
        success: 'Note saved successfully',
      },
      edit: {
        title: 'Edit Project',
      },
      fields: {
        id: 'Id',
        projectID: 'Project ID',
        title: 'Title',
        faculty: 'Faculty',
        projectManager: 'Manager',
        accountingProjectNo: 'Accounting No.',
        status: 'Status',
        projectCreate: 'Create Project',
        projectRelease: 'Project Release',
        projectImplementation: 'Project Implementation',
        projectFunding: 'Prepare Funding Application',
        projectReport: 'ProjectReport',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {
        status: {
          'CREATED/PROJECT_RELEASE_OPEN': 'RELEASE OPEN',
          'CREATED/REQUESTED_FOR_RELEASE': 'RELEASE REQUESTED',
          RUNNING: 'RUNNING',
          'CREATED/REVISION_REQUESTED': 'REVISION REQUESTED',
          REJECTED: 'REJECTED',
          'RUNNING/FUNDING_APPROVED': 'FUNDING APPROVED',
          'RUNNING/FUNDING_REJECTED': 'FUNDING REJECTED',
          'RUNNING/WITHOUT_FUNDING': 'WITHOUT FUNDING',
          CLOSED: 'CLOSED',
          COMPLETED: 'COMPLETED',
        },
      },
      new: {
        title: 'New Project',
      },
      view: {
        title: 'View Project',
      },
    },

    projectCreate: {
      name: 'projectCreate',
      label: 'Project Create',
      menu: 'Project Create',
      exporterFileName: 'projectCreate_export',
      list: {
        menu: 'Project Create',
        title: 'Project Create',
      },
      create: {
        success: 'Create Project saved successfully',
      },
      update: {
        success: 'Create Project updated successfully',
      },
      destroy: {
        success: 'Create Project deleted successfully',
      },
      destroyAll: {
        success: 'Create Project(s) deleted successfully',
      },
      edit: {
        title: 'Edit Create Project',
      },
      alert: {
        researchFocus:
          'Please select one or more research foci according to the classification of Statistics Austria (six-digits). If your faculty has defined its own research foci, please additionally select one or more of them.',
        projectTeam:
          'Deletion of team members is not possible (only admins can). In order to disable team members for a project click on Project Teams and disable the person for the required project.',
      },
      fields: {
        id: 'Id',
        visibleExternallyEnum:
          'Do you want the project to be visible on the SFU website?',
        title: 'Title',
        description: 'Description',
        researchFocus: 'Research Focus',
        proposalResearchFocus: 'Proposal of Research Focus',
        subjectArea: 'Subject Area',
        proposalSubjectArea: 'Proposal of Subject Area',
        faculty: 'Faculty',
        teamMember: 'Team member',
        projectManager: 'Project manager',
        cooperationPartnerEnum: 'Cooperation partner',
        cooperationPartner: 'Cooperation partner',
        plannedAmount: 'Planned amount',
        timeSheet: 'Time sheets',
        costsPlan: 'Costs plans',
        descriptionThirdPartyFunding: 'Tender text or project description',
        financialGuideline: 'Financial guidelines',
        fundingAgencyEnum: 'Funding agency',
        fundingAgency: 'Funding agency',
        projectDuration: 'Project duration - Months',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {
        cooperationPartnerEnum: {
          yes: 'Yes',
          no: 'No',
        },
        visibleExternallyEnum: {
          yes: 'Yes',
          no: 'No',
        },
        fundingAgencyEnum: {
          yes: 'Yes',
          no: 'No',
        },
      },
      new: {
        title: 'New Create Project',
      },
      view: {
        title: 'View Create Project',
      },
    },

    projectRelease: {
      name: 'projectRelease',
      label: 'Project Releases',
      menu: 'Project Releases',
      exporterFileName: 'projectRelease_export',
      list: {
        menu: 'Project Releases',
        title: 'Project Releases',
      },
      create: {
        success: 'Project Release saved successfully',
      },
      update: {
        success: 'Project Release updated successfully',
      },
      destroy: {
        success: 'Project Release deleted successfully',
      },
      destroyAll: {
        success: 'Project Release(s) deleted successfully',
      },
      edit: {
        title: 'Edit Project Release',
      },
      fields: {
        id: 'Id',
        project: 'Project',
        faculty: 'Faculty',
        projectReleaseRequest: 'Release requests',
        status: 'Status',
        numberOfRequests: 'Number of requests',
        signedDocument: 'Signed documents',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {
        status: {
          created: 'Created',
          rejected: 'Rejected',
          released: 'Released',
          revise: 'Revise',
          releaseRequested: 'Release requested',
          revisionRequested: 'Revision requested',
          vRUploadSignedDocuments: 'VR - Upload signed documents',
          completed: 'Completed',
        },
      },
      new: {
        title: 'New Project Release',
      },
      view: {
        title: 'View Project Release',
      },
    },

    projectReleaseRequest: {
      name: 'projectRelease',
      label: 'Project Release Requests',
      menu: 'Project Release Requests',
      exporterFileName: 'projectReleaseRequest_export',
      list: {
        menu: 'Project Release Requests',
        title: 'Project Release Requests',
      },
      create: {
        success: 'Project Release Request saved successfully',
      },
      update: {
        success: 'Project Release Request updated successfully',
        warning:
          'Please be aware that rejecting the project release will completely reject the project.',
      },
      destroy: {
        success: 'Project Release Request deleted successfully',
      },
      destroyAll: {
        success: 'Project Release Requests(s) deleted successfully',
      },
      edit: {
        title: 'Edit Project Release Request',
      },
      fields: {
        id: 'Id',
        projectID: 'Project ID',
        decision: 'Decision',
        faculty: 'Faculty',
        date: 'Date',
        comment: 'Comment',
        status: 'Status',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {
        decision: {
          approved: 'Approved',
          rejected: 'Rejected',
          revise: 'Revise',
        },
        status: {
          requested: 'Requested',
          processed: 'Processed',
          revisionRequested: 'Revision requested',
          deprecated: 'Deprecated',
        },
      },
      new: {
        title: 'New Project Release Request',
      },
      view: {
        title: 'View Project Release Requests',
      },
    },

    projectImplementation: {
      name: 'projectImplementation',
      label: 'Project Implementations',
      menu: 'Project Implementations',
      exporterFileName: 'projectImplementation_export',
      list: {
        menu: 'Project Implementations',
        title: 'Project Implementations',
      },
      create: {
        success: 'Project Implementation saved successfully',
      },
      update: {
        success: 'Project Implementation updated successfully',
      },
      destroy: {
        success: 'Project Implementation deleted successfully',
      },
      destroyAll: {
        success: 'Project Implementation(s) deleted successfully',
      },
      edit: {
        title: 'Edit Project Implementation',
      },
      releaseCoopPartnerContracts: {
        warning:
          'No contract is added. Please add Cooperation Partner Contracts and try again.',
      },
      releaseEmploymentContracts: {
        warning:
          'No contract is added. Please add Employment Contracts and try again.',
      },
      notifyFundingAgencies: {
        warning: 'No Funding Applications added. Please add and try again.',
      },
      fields: {
        id: 'Id',
        ethicsCommitteeEnum: 'Ethics Committee',
        ethicsCommitteeDecision: 'Ethics Committee Decision',
        cooperationContractsEnum: 'Cooperation Contracts',
        cooperationPartnerContracts: 'Cooperation Partner Contracts',
        employmentContractsEnum: 'Employment Contracts',
        employmentContracts: 'Employment Contracts',
        fundingEnum: 'Funding',
        projectFunding: 'ProjectFunding',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {
        ethicsCommitteeEnum: {
          yes: 'Yes',
          no: 'No',
        },
        cooperationContractsEnum: {
          yes: 'Yes',
          no: 'No',
        },
        employmentContractsEnum: {
          yes: 'Yes',
          no: 'No',
        },
        fundingEnum: {
          thirdPartyFunding: 'Third-party funding',
          selfFinancedProject: 'Self-financed project',
        },
      },
      new: {
        title: 'New Project Implementation',
      },
      view: {
        title: 'View Project Implementation',
      },
    },

    projectFunding: {
      name: 'projectFunding',
      label: 'Project Funding',
      menu: 'Project Funding',
      exporterFileName: 'projectFunding_export',
      list: {
        menu: 'Project Funding',
        title: 'Project Funding',
      },
      create: {
        success: 'Prepare Funding Application saved successfully',
      },
      update: {
        success: 'Prepare Funding Application updated successfully',
      },
      destroy: {
        success: 'Prepare Funding Application deleted successfully',
      },
      destroyAll: {
        success: 'Prepare Funding Application(s) deleted successfully',
      },
      edit: {
        title: 'Edit Prepare Funding Application',
      },
      requestAccountingProjectNumberAllocation: {
        success:
          'Assignment of the Project Number successfully requested from Accounting',
      },
      fields: {
        id: 'Id',
        fundingEnum: 'Funding',
        accountingProjectNo: 'Project No from Accounting',
        grants: 'Grants',
        status: 'Status',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {
        fundingEnum: {
          thirdPartyFunding: 'Third-party funding',
          selfFinancedProject: 'Self-financed project',
          null: 'Undefined',
        },
      },
      new: {
        title: 'New Prepare Funding Application',
      },
      view: {
        title: 'View Prepare Funding Application',
      },
    },

    projectReport: {
      name: 'projectReport',
      label: 'Project Reports',
      menu: 'Project Reports',
      exporterFileName: 'projectReport_export',
      list: {
        menu: 'Project Reports',
        title: 'Project Reports',
      },
      create: {
        success: 'Project Report saved successfully',
      },
      update: {
        success: 'Project Report updated successfully',
      },
      destroy: {
        success: 'Project Report deleted successfully',
      },
      destroyAll: {
        success: 'Project Report(s) deleted successfully',
      },
      edit: {
        title: 'Edit Project Report',
      },
      fields: {
        id: 'Id',
        reportFile: 'Report',
        internalReportFile: 'Internal Report',
        projectDurationExtension: 'Extension of project duration',
        grantorsExtensionApprovalFile: "Grantor's extension approval",
        newEndDate: 'New end date',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {
        projectDurationExtension: {
          enabled: 'Enabled',
          disabled: 'Disabled',
        },
      },
      new: {
        title: 'New Project Report',
      },
      view: {
        title: 'View Project Report',
      },
      enabled: 'Enabled',
      disabled: 'Disabled',
    },

    faculty: {
      name: 'faculty',
      label: 'Faculties',
      menu: 'Faculties',
      exporterFileName: 'faculty_export',
      list: {
        menu: 'Faculties',
        title: 'Faculties',
      },
      create: {
        success: 'Faculty saved successfully',
      },
      update: {
        success: 'Faculty updated successfully',
      },
      destroy: {
        success: 'Faculty deleted successfully',
      },
      destroyAll: {
        success: 'Faculty(s) deleted successfully',
      },
      edit: {
        title: 'Edit Faculty',
      },
      fields: {
        id: 'Id',
        name: 'Name',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {},
      new: {
        title: 'New Faculty',
      },
      view: {
        title: 'View Faculty',
      },
    },

    location: {
      name: 'location',
      label: 'Locations',
      menu: 'Locations',
      exporterFileName: 'location_export',
      doEnableSuccess: 'Location enabled successfully',
      doDisableSuccess: 'Location disabled successfully',
      doDisableAllSuccess: 'Location(s) disabled successfully',
      doEnableAllSuccess: 'Location(s) enabled successfully',
      list: {
        menu: 'Locations',
        title: 'Locations',
      },
      create: {
        success: 'Location saved successfully',
      },
      update: {
        success: 'Location updated successfully',
      },
      destroy: {
        success: 'Location deleted successfully',
      },
      destroyAll: {
        success: 'Location(s) deleted successfully',
      },
      edit: {
        title: 'Edit Location',
      },
      fields: {
        id: 'Id',
        name: 'Name',
        faculty: 'Faculty',
        status: 'Status',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {},
      new: {
        title: 'New Location',
      },
      view: {
        title: 'View Location',
      },
    },

    centerInstitution: {
      name: 'centerInstitution',
      label: 'Centers / Institutions',
      menu: 'Centers / Institutions',
      exporterFileName: 'centerInstitution_export',
      doEnableSuccess: 'Center/Institution enabled successfully',
      doDisableSuccess: 'Center/Institution disabled successfully',
      doDisableAllSuccess: 'Center/Institution(s) disabled successfully',
      doEnableAllSuccess: 'Center/Institution(s) enabled successfully',
      list: {
        menu: 'Centers / Institutions',
        title: 'Centers / Institutions',
      },
      create: {
        success: 'Center / Institution saved successfully',
      },
      update: {
        success: 'Center / Institution updated successfully',
      },
      destroy: {
        success: 'Center / Institution deleted successfully',
      },
      destroyAll: {
        success: 'Center / Institution(s) deleted successfully',
      },
      edit: {
        title: 'Edit Center / Institution',
      },
      fields: {
        id: 'Id',
        name: 'Name',
        faculty: 'Faculty',
        location: 'Location',
        status: 'Status',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {},
      new: {
        title: 'New Center / Institution',
      },
      view: {
        title: 'View Center / Institution',
      },
    },

    outpatientClinic: {
      name: 'outpatientClinic',
      label: 'Outpatient Clinics',
      menu: 'Outpatient Clinics',
      exporterFileName: 'outpatientClinic_export',
      doEnableSuccess: 'Outpatient Clinic enabled successfully',
      doDisableSuccess: 'Outpatient Clinic disabled successfully',
      doDisableAllSuccess: 'Outpatient Clinic(s) disabled successfully',
      doEnableAllSuccess: 'Outpatient Clinic(s) enabled successfully',

      list: {
        menu: 'Outpatient Clinics',
        title: 'Outpatient Clinics',
      },
      create: {
        success: 'Outpatient Clinic saved successfully',
      },
      update: {
        success: 'Outpatient Clinic updated successfully',
      },
      destroy: {
        success: 'Outpatient Clinic deleted successfully',
      },
      destroyAll: {
        success: 'Outpatient Clinic(s) deleted successfully',
      },
      edit: {
        title: 'Edit Outpatient Clinic',
      },
      fields: {
        id: 'Id',
        name: 'Name',
        faculty: 'Faculty',
        location: 'Location',
        headOfOutpatientClinic: 'Head of the OC',
        address: 'Address',
        emailAddress: 'Email Address',
        status: 'Status',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {},
      new: {
        title: 'New Outpatient Clinic (OC)',
      },
      view: {
        title: 'View Outpatient Clinic',
      },
    },

    subjectArea: {
      name: 'subjectArea',
      label: 'Subject Areas',
      menu: 'Subject Areas',
      exporterFileName: 'subjectArea_export',
      list: {
        menu: 'Subject Areas',
        title: 'Subject Areas',
      },
      create: {
        success: 'Subject Area saved successfully',
      },
      update: {
        success: 'Subject Area updated successfully',
      },
      destroy: {
        success: 'Subject Area deleted successfully',
      },
      destroyAll: {
        success: 'Subject Area(s) deleted successfully',
      },
      edit: {
        title: 'Edit Subject Area',
      },
      fields: {
        id: 'Id',
        name: 'Name',
        status: 'Status',
        project: 'Project',
        comment: 'Comment',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {
        status: {
          approved: 'Approved',
          revise: 'Revise',
          proposed: 'Proposed',
        },
      },
      new: {
        title: 'New Subject Area',
      },
      view: {
        title: 'View Subject Area',
      },
      importer: {
        title: 'Import Subject Area',
        fileName: 'subjectArea_import_template',
        hint:
          'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    researchFocus: {
      name: 'researchFocus',
      label: 'Research Focuses',
      menu: 'Research Focuses',
      exporterFileName: 'researchFocus_export',
      list: {
        menu: 'Research Focuses',
        title: 'Research Focuses',
      },
      create: {
        success: 'Research Focus saved successfully',
      },
      update: {
        success: 'Research Focus updated successfully',
      },
      destroy: {
        success: 'Research Focus deleted successfully',
      },
      destroyAll: {
        success: 'Research Focus(s) deleted successfully',
      },
      edit: {
        title: 'Edit Research Focus',
      },
      fields: {
        id: 'Id',
        name: 'Name',
        status: 'Status',
        comment: 'Comment',
        project: 'Project',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {
        status: {
          approved: 'Approved',
          revise: 'Revise',
          proposed: 'Proposed',
        },
      },
      new: {
        title: 'New Research Focus',
      },
      view: {
        title: 'View Research Focus',
      },
      importer: {
        title: 'Import Research Focuses',
        fileName: 'researchFocus_import_template',
        hint:
          'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    fundingAgency: {
      name: 'fundingAgency',
      label: 'Funding Agencies',
      menu: 'Funding Agencies',
      exporterFileName: 'fundingAgency_export',
      list: {
        menu: 'Funding Agencies',
        title: 'Funding Agencies',
      },
      create: {
        success: 'Funding Agency saved successfully',
      },
      update: {
        success: 'Funding Agency updated successfully',
      },
      destroy: {
        success: 'Funding Agency deleted successfully',
      },
      destroyAll: {
        success: 'Funding Agency(s) deleted successfully',
      },
      edit: {
        title: 'Edit Funding Agency',
      },
      fields: {
        id: 'Id',
        name: 'Name',
        management: 'Management',
        address: 'Address',
        contactPerson: 'Contact Person',
        contactEmail: 'Contact Email',
        phoneNumber: 'Phone Number',
        linkToWebsite: 'Link to Website',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {},
      new: {
        title: 'New Funding Agency',
      },
      view: {
        title: 'View Funding Agency',
      },
    },

    grant: {
      name: 'grant',
      label: 'Grant',
      menu: 'Grants',
      exporterFileName: 'grant_export',
      list: {
        menu: 'Grants',
        title: 'Grants',
      },
      create: {
        success: 'Grant saved successfully',
      },
      update: {
        success: 'Grant updated successfully',
      },
      destroy: {
        success: 'Grant deleted successfully',
      },
      destroyAll: {
        success: 'Grant(s) deleted successfully',
      },
      edit: {
        title: 'Edit Grant',
      },
      sendingNotifications: {
        success: 'Notifications successfully sent to Grantors',
      },
      fields: {
        id: 'Id',
        fundingAgency: 'Funding Agency',
        contactPerson: 'Contact Person',
        tenderUrl: 'Tender URL',
        deadlineForSubmission: 'Deadline for Submission',
        availabilityOfGrantorDecision: 'Funding decision expected by ...',
        projectDuration: 'Project Duration',
        decision: 'Decision',
        decisionFile: 'Decision',
        amount: 'Amount',
        status: 'Status',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {
        status: {
          notificationSent: 'Notified',
        },
        decision: {
          positive: 'Positive',
          negative: 'Negative',
        },
      },
      new: {
        title: 'New Grant',
      },
      view: {
        title: 'View Grant',
      },
    },

    cooperationPartner: {
      name: 'cooperationPartner',
      label: 'Cooperation Partners',
      menu: 'Cooperation Partners',
      exporterFileName: 'cooperationPartner_export',
      list: {
        menu: 'Cooperation Partners',
        title: 'Cooperation Partners',
      },
      create: {
        success: 'Cooperation Partner saved successfully',
      },
      update: {
        success: 'Cooperation Partner updated successfully',
      },
      destroy: {
        success: 'Cooperation Partner deleted successfully',
      },
      destroyAll: {
        success: 'Cooperation Partner(s) deleted successfully',
      },
      edit: {
        title: 'Edit Cooperation Partner',
      },
      fields: {
        id: 'Id',
        lastName: 'Last Name',
        firstName: 'First Name',
        title: 'Title',
        affiliation: 'Affiliation',
        emailAddress: 'Email Address',
        phoneNumber: 'Phone Number',
        institutionName: 'Institution Name',
        address: 'Address',
        contactPerson: 'Contact Person',
        webAddress: 'Web Address',
        partner: 'Partner',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {
        partner: {
          person: 'Person',
          institution: 'Institution',
        },
      },
      new: {
        title: 'New Cooperation Partner',
      },
      view: {
        title: 'View Cooperation Partner',
      },
    },

    viceDeanResearch: {
      name: 'viceDeanResearch',
      label: 'Vice-Dean Research',
      menu: 'Vice-Dean Research',
      exporterFileName: 'viceDeanResearch_export',
      list: {
        menu: 'Vice-Dean Research',
        title: 'Vice-Dean Research',
      },
      create: {
        success: 'Vice-Dean Research saved successfully',
      },
      update: {
        success: 'Vice-Dean Research updated successfully',
      },
      destroy: {
        success: 'Vice-Dean Research deleted successfully',
      },
      destroyAll: {
        success: 'Vice-Dean Research(s) deleted successfully',
      },
      edit: {
        title: 'Edit Vice-Dean Research',
      },
      fields: {
        id: 'Id',
        faculty: 'Faculty',
        dean: 'Dean',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {},
      new: {
        title: 'New Vice-Dean Research',
      },
      view: {
        title: 'View Vice-Dean Research',
      },
    },

    chancellor: {
      name: 'chancellor',
      label: 'Chancellors',
      menu: 'Chancellors',
      exporterFileName: 'chancellor_export',
      list: {
        menu: 'Chancellors',
        title: 'Chancellors',
      },
      create: {
        success: 'Chancellor saved successfully',
      },
      update: {
        success: 'Chancellor updated successfully',
      },
      destroy: {
        success: 'Chancellor deleted successfully',
      },
      destroyAll: {
        success: 'Chancellor(s) deleted successfully',
      },
      edit: {
        title: 'Edit Chancellor',
      },
      fields: {
        id: 'Id',
        faculty: 'Faculty',
        contactEmail: 'Contact Email',
        contactPerson: 'Contact Person',
        phoneNumber: 'Phone Number',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {},
      new: {
        title: 'New Chancellor',
      },
      view: {
        title: 'View Chancellor',
      },
    },

    legalDepartment: {
      name: 'legalDepartment',
      label: 'Legal Departments',
      menu: 'Legal Departments',
      exporterFileName: 'legalDepartment_export',
      list: {
        menu: 'Legal Departments',
        title: 'Legal Departments',
      },
      create: {
        success: 'Legal Department saved successfully',
      },
      update: {
        success: 'Legal Department updated successfully',
      },
      destroy: {
        success: 'Legal Department deleted successfully',
      },
      destroyAll: {
        success: 'Legal Department(s) deleted successfully',
      },
      edit: {
        title: 'Edit Legal Department',
      },
      fields: {
        id: 'Id',
        contactEmail: 'Contact Email',
        contactPerson: 'Contact Person',
        phoneNumber: 'Phone Number',
        user: 'User',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {},
      new: {
        title: 'New Legal Department',
      },
      view: {
        title: 'View Legal Department',
      },
    },

    humanResource: {
      name: 'humanResource',
      label: 'Human Resources',
      menu: 'Human Resources',
      exporterFileName: 'humanResource_export',
      list: {
        menu: 'Human Resources',
        title: 'Human Resources',
      },
      create: {
        success: 'Human Resource saved successfully',
      },
      update: {
        success: 'Human Resource updated successfully',
      },
      destroy: {
        success: 'Human Resource deleted successfully',
      },
      destroyAll: {
        success: 'Human Resource(s) deleted successfully',
      },
      edit: {
        title: 'Edit Human Resource',
      },
      fields: {
        id: 'Id',
        contactEmail: 'Contact Email',
        contactPerson: 'Contact Person',
        phoneNumber: 'Phone Number',
        user: 'User',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {},
      new: {
        title: 'New Human Resource',
      },
      view: {
        title: 'View Human Resource',
      },
    },

    teamMember: {
      name: 'teamMember',
      label: 'Team Members',
      menu: 'Team Members',
      exporterFileName: 'teamMember_export',
      list: {
        menu: 'Team Members',
        title: 'Team Members',
      },
      create: {
        success: 'Team Member saved successfully',
      },
      update: {
        success: 'Team Member updated successfully',
      },
      destroy: {
        success: 'Team Member deleted successfully',
      },
      destroyAll: {
        success: 'Team Member(s) deleted successfully',
      },
      edit: {
        title: 'Edit Team Member',
      },
      fields: {
        id: 'Id',
        user: 'User',
        status: 'Status',
        contract: 'Contract',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {
        status: {
          active: 'Active',
          inactive: 'Inactive',
        },
      },
      new: {
        title: 'New Team Member',
      },
      view: {
        title: 'View Team Member',
      },
    },

    projectTeam: {
      name: 'projectTeam',
      label: 'Project Teams',
      menu: 'Project Teams',
      exporterFileName: 'projectTeam_export',
      list: {
        menu: 'Project Teams',
        title: 'Project Teams',
      },
      create: {
        success: 'Project Team saved successfully',
      },
      update: {
        success: 'Project Team updated successfully',
      },
      destroy: {
        success: 'Project Team deleted successfully',
      },
      destroyAll: {
        success: 'Project Team(s) deleted successfully',
      },
      edit: {
        title: 'Edit Project Team',
      },
      fields: {
        id: 'Id',
        teamMember: 'Team Member',
        projectManager: 'Project Manager',
        project: 'Project',
        projectID: 'Project ID',
        manager: 'Manager',
        team: 'Team',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {},
      new: {
        title: 'New Project Team',
      },
      view: {
        title: 'View Project Team',
      },
    },

    projectManager: {
      name: 'projectManager',
      label: 'Project Managers',
      menu: 'Project Managers',
      exporterFileName: 'projectManager_export',
      list: {
        menu: 'Project Managers',
        title: 'Project Managers',
      },
      create: {
        success: 'Project Manager saved successfully',
      },
      update: {
        success: 'Project Manager updated successfully',
      },
      destroy: {
        success: 'Project Manager deleted successfully',
      },
      destroyAll: {
        success: 'Project Manager(s) deleted successfully',
      },
      edit: {
        title: 'Edit Project Manager',
      },
      fields: {
        id: 'Id',
        user: 'User',
        status: 'Status',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {
        status: {
          active: 'Active',
          inactive: 'Inactive',
        },
      },
      new: {
        title: 'New Project Manager',
      },
      view: {
        title: 'View Project Manager',
      },
    },

    template: {
      name: 'template',
      label: 'Templates',
      menu: 'Templates',
      list: {
        menu: 'Templates',
        title: 'Templates',
      },
      create: {
        success: 'Template saved successfully',
      },
      update: {
        success: 'Template updated successfully',
      },
      destroy: {
        success: 'Template deleted successfully',
      },
      destroyAll: {
        success: 'Template(s) deleted successfully',
      },
      edit: {
        title: 'Edit Template',
      },
      fields: {
        id: 'Id',
        template: 'Template',
        templateType: 'Template Type',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {
        templateType: {
          costs: 'Costs',
          gantt: 'Gantt',
          projectReport: 'Project Report',
          descriptionThirdPartyFunding: 'Description/Third-party funding',
          ethicsCommitteeGuidelines: 'Ethics Committee Guidelines',
          cooperationAgreement: 'Cooperation Agreement',
          employmentContract: 'Employment Contract',
          projectPlanSelfFinancing: 'Project Plan self-financing',
        },
      },
      new: {
        title: 'New Template',
      },
      view: {
        title: 'View Template',
      },
    },

    knowledgeBase: {
      name: 'knowledgeBase',
      label: 'Knowledge Base',
      menu: 'Knowledge Base',
      list: {
        menu: 'Knowledge Base',
        title: 'Knowledge Base',
      },
      create: {
        success: 'Issue saved successfully',
      },
      update: {
        success: 'Issue updated successfully',
      },
      destroy: {
        success: 'Issue deleted successfully',
      },
      destroyAll: {
        success: 'Issue deleted successfully',
      },
      edit: {
        title: 'Edit Issue',
      },
      fields: {
        id: 'Id',
        issue: 'Issue',
        resolution: 'Resolution',
        tag: 'Tag',
        document: 'Document',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {},
      new: {
        title: 'New Issue',
      },
      view: {
        title: 'View Issue',
      },
    },

    cooperationPartnerContract: {
      name: 'cooperationPartnerContract',
      label: 'Cooperation Partner Contracts',
      menu: 'Cooperation Partner Contracts',
      exporterFileName: 'cooperationPartnerContract_export',
      list: {
        menu: 'Cooperation Partner Contracts',
        title: 'Cooperation Partner Contracts',
      },
      create: {
        success: 'Cooperation Partner Contract saved successfully',
      },
      update: {
        success: 'Cooperation Partner Contract updated successfully',
      },
      updateStatus: {
        success: 'Cooperation Partner Contract status updated successfully',
      },
      releaseRequest: {
        success:
          'Release of Cooperation Partner Contracts successfully requested',
      },
      destroy: {
        success: 'Cooperation Partner Contract deleted successfully',
      },
      destroyAll: {
        success: 'Cooperation Partner Contracts(s) deleted successfully',
      },
      edit: {
        title: 'Edit Cooperation Partner Contract',
      },
      fields: {
        id: 'Id',
        project: 'Project ID',
        cooperationPartner: 'Cooperation Partner',
        contractDraft: 'Contract Draft',
        contract: 'Contract',
        status: 'Status',
        contractType: 'Contract Type',
        dateRange: 'Date',
        date: 'Date',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {
        status: {
          released: 'Released',
          revise: 'Revise',
          releaseRequested: 'Release Requested',
        },
      },
      new: {
        title: 'New Cooperation Partner Contracts',
      },
      view: {
        title: 'View Cooperation Partner Contracts',
      },
    },

    employmentContract: {
      name: 'employmentContract',
      label: 'Employment Contracts',
      menu: 'Employment Contracts',
      exporterFileName: 'employmentContract_export',
      list: {
        menu: 'Employment Contracts',
        title: 'Employment Contracts',
      },
      create: {
        success: 'Employment Contract saved successfully',
      },
      update: {
        success: 'Employment Contract updated successfully',
      },
      destroy: {
        success: 'Employment Contract deleted successfully',
      },
      destroyAll: {
        success: 'Employment Contract(s) deleted successfully',
      },
      updateStatus: {
        success: 'Employment Contract status updated successfully',
      },
      releaseRequest: {
        success: 'Release of Employment Contracts successfully requested',
      },
      edit: {
        title: 'Edit Employment Contract',
      },
      fields: {
        id: 'Id',
        project: 'Project ID',
        teamMember: 'Team Member',
        contractDraft: 'Contract Draft',
        contract: 'Contract',
        contractType: 'Contract Type',
        status: 'Status',
        dateRange: 'Date',
        date: 'Date',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {
        status: {
          released: 'Released',
          revise: 'Revise',
          releaseRequested: 'Release Requested',
        },
      },
      new: {
        title: 'New Employment Contract',
      },
      view: {
        title: 'View Employment Contract',
      },
    },

    projectGantt: {
      name: 'projectGantt',
      label: 'Gantt',
      menu: 'Gantt',
      exporterFileName: 'gantt_export',
      list: {
        menu: 'Gantt',
        title: 'Gantt',
      },
      create: {
        success: 'Gantt saved successfully',
      },
      update: {
        success: 'Gantt updated successfully',
      },
      destroy: {
        success: 'Gantt deleted successfully',
      },
      destroyAll: {
        success: 'Gantt deleted successfully',
      },
      edit: {
        title: 'Edit Gantt',
      },
      fields: {
        id: 'Id',
        workPackage: 'Work Package',
        ganttChart: 'Gantt Chart',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {},
      new: {
        title: 'New Gantt',
      },
      view: {
        title: 'View Gantt',
      },
    },

    workPackage: {
      name: 'workPackage',
      label: 'Work Packages',
      menu: 'Work Packages',
      exporterFileName: 'workPackage_export',
      list: {
        menu: 'Work Packages',
        title: 'Work Packages',
      },
      create: {
        success: 'Work Package saved successfully',
      },
      update: {
        success: 'Work Package updated successfully',
      },
      destroy: {
        success: 'Work Package deleted successfully',
      },
      destroyAll: {
        success: 'Work Package(s) deleted successfully',
      },
      edit: {
        title: 'Edit Work Package',
      },
      fields: {
        id: 'Id',
        name: 'Name',
        milestoneRange: 'Milestone',
        milestone: 'Milestone',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {},
      new: {
        title: 'New Work Package',
      },
      view: {
        title: 'View Work Package',
      },
    },

    projectCompletion: {
      name: 'projectCompletion',
      label: 'Project Completions',
      menu: 'Project Completions',
      exporterFileName: 'projectCompletion_export',
      list: {
        menu: 'Project Completions',
        title: 'Project Completions',
      },
      create: {
        success: 'Project Completion saved successfully',
      },
      update: {
        success: 'Project Completion updated successfully',
        successfullyCompleted: 'Project Completed successfully',
      },
      destroy: {
        success: 'Project Completion deleted successfully',
      },
      destroyAll: {
        success: 'Project Completion(s) deleted successfully',
      },
      edit: {
        title: 'Edit Project Completion',
      },
      fields: {
        id: 'Id',
        finalReportFile: 'Final report',
        finalProjectBudgetAndFunding: 'Final Budget and Funding',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {},
      new: {
        title: 'New Project Completion',
      },
      view: {
        title: 'View Project Completion',
      },
    },
  },

  auth: {
    profile: {
      title: 'Edit Profile',
      success: 'Profile updated successfully',
    },
    createAnAccount: 'Request account registration',
    rememberMe: 'Remember me',
    forgotPassword: 'Forgot password',
    signin: 'Sign in',
    signup: 'Sign up',
    requestRegistration: 'Request registration',
    signout: 'Sign out',
    backToSignin: 'Back to Sign in',
    alreadyHaveAnAccount: 'Already have an account? Sign in.',
    signinWithAnotherAccount: 'Sign in with another account',
    emailUnverified: {
      message: `Please confirm your email at <strong>{0}</strong> to continue.`,
      submit: `Resend email verification`,
    },
    emptyPermissions: {
      message: `You have no permissions yet. Wait for the admin to grant you privileges.`,
    },
    passwordResetRequest: {
      message: 'Request password reset',
      error: `Email not recognized`,
    },
    passwordReset: {
      message: 'Reset password',
    },
    passwordChange: {
      title: 'Change Password',
      success: 'Password successfully changed',
      mustMatch: 'Passwords must match',
    },
    emailAddressVerificationEmail: {
      error: `Email not recognized`,
    },
    verificationEmailSuccess: `Verification email sent successfully`,
    passwordResetEmailSuccess: `Password reset email sent successfully`,
    passwordResetRequestSuccess: `Password reset requested successfully`,
    passwordResetSuccess: `Password changed successfully`,
    verifyEmail: {
      success: 'Email successfully verified',
      message: 'Just a moment, your email is being verified...',
    },
    //TODO
    //Text to be specified from SFU
    registrationSuccess:
      'Registration requested successful! You will receive email with details once your registration is processed by the Administrators.',
  },

  passwordChange: {
    title: 'Change password',
    currentPassword: 'Current password',
    newPassword: 'New password',
    confirmNewPassword: 'Confirm new password',
    containsEightCharacters: '8 Characters',
    containsNumber: 'Contains Number',
    containsUppercase: 'Contains Uppercase',
    containsSpecialCharacter: 'Contains Special Character',
    passwordUpdateSuccess: 'Password successfully updated',
    passwordUpdateFailed: 'Password update failed',
  },

  roles: {
    //TODO
    //Define description for the user roles
    itAdmin: {
      label: 'IT Administrator',
      description: 'Full access to all resources',
    },
    vrAdmin: {
      label: 'VR Administrator',
      description: 'Full access to all resources',
    },
    projectManager: {
      label: 'Project Manager',
      //description: 'Full access to Project resources',
    },
    projectTeam: {
      label: 'Project Team',
      //description: 'Edit access to Project resources',
    },
    deanship: {
      label: 'Deanship',
      //description: 'Deans office',
    },
    chancellor: {
      label: 'Chancellor',
      //description: 'Access to view audit logs',
    },
    legalDepartment: {
      label: 'Legal Department',
      //description: `Full access to manage users roles`,
    },
    hrDepartment: {
      label: 'HR Department',
      //description: 'Edit access to all entities',
    },
    readAdmin: {
      label: 'Read Admin',
      //description: 'Full read access',
    },
  },

  iam: {
    title: 'Identity and Access Management',
    menu: 'IAM',
    disable: 'Disable',
    disabled: 'Disabled',
    enabled: 'Enabled',
    enable: 'Enable',
    removeRoles: 'Remove roles',
    doEnableSuccess: 'User enabled successfully',
    doDisableSuccess: 'User disabled successfully',
    doDisableAllSuccess: 'User(s) disabled successfully',
    doEnableAllSuccess: 'User(s) enabled successfully',
    doAddSuccess: 'User(s) saved successfully',
    doSignupSuccess: 'User registration requested successfully',
    doUpdateSuccess: 'User saved successfully',
    userApprovedSuccess: 'User approved successfully',
    viewBy: 'View By',
    users: {
      name: 'users',
      label: 'Users',
      exporterFileName: 'users_export',
      doRemoveAllSelectedSuccess: 'Permissions removed successfully',
      doDestroyAllSuccess: 'Users successfully deleted',
    },
    roles: {
      label: 'Roles',
      doRemoveAllSelectedSuccess: 'Permissions removed successfully',
    },
    edit: {
      title: 'Edit User',
    },
    new: {
      title: 'New User(s)',
      titleModal: 'New User',
      emailsHint:
        'Separate multiple email addresses using the comma character.',
    },
    view: {
      title: 'View User',
      activity: 'Activity',
    },
    approve: {
      title: 'Approve user',
      approve: 'Approve',
    },
    importer: {
      title: 'Import Users',
      fileName: 'users_import_template',
      hint:
        'Files/Images columns must be the URLs of the files separated by space. Relationships must be the ID of the referenced records separated by space. Roles must be the role ids separated by space.',
    },
    errors: {
      userAlreadyExists: 'User with this email already exists',
      userNotFound: 'User not found',
      disablingHimself: `You can't disable yourself`,
      revokingOwnPermission: `You can't revoke your own owner permission`,
    },
  },

  user: {
    fields: {
      id: 'Id',
      authenticationUid: 'Authentication Uid',
      avatars: 'Avatar',
      email: 'Email',
      emails: 'Email(s)',
      fullName: 'Name',
      firstName: 'First Name',
      lastName: 'Last Name',
      status: 'Status',
      disabled: 'Disabled',
      phoneNumber: 'Phone Number',
      faculty: 'Faculty',
      location: 'Location',
      centerInstitution: 'Center/Institution',
      outpatientClinic: 'Outpatient Clinic',
      academicTitle: 'Academic Title',
      role: 'Role',
      createdAt: 'Created at',
      updatedAt: 'Updated at',
      roleUser: 'Role/User',
      roles: 'Roles',
      createdAtRange: 'Created at',
      password: 'Password',
      oldPassword: 'Old Password',
      newPassword: 'New Password',
      newPasswordConfirmation: 'New Password Confirmation',
      rememberMe: 'Remember me',
    },
    enabled: 'Enabled',
    disabled: 'Disabled',
    validations: {
      // eslint-disable-next-line
      email: 'Email ${value} is invalid',
    },
    status: {
      enabled: 'Enabled',
      disabled: 'Disabled',
      releasePending: 'Release pending',
    },
  },

  auditLog: {
    menu: 'Audit Logs',
    title: 'Audit Logs',
    exporterFileName: 'audit_log_export',
    entityNamesHint: 'Separate multiple entities using the comma character.',
    fields: {
      id: 'Id',
      timestampRange: 'Period',
      entityName: 'Entity',
      entityNames: 'Entities',
      entityId: 'Entity ID',
      action: 'Action',
      values: 'Values',
      timestamp: 'Date',
      createdByEmail: 'User Email',
    },
  },
  settings: {
    title: 'Settings',
    menu: 'Settings',
    save: {
      /* success:
        'Settings saved successfully. The page will reload in {0} seconds for changes to take effect.', */
      success: 'Settings saved successfully.',
    },
    fields: {
      accountingDepartmentMailbox: 'Accounting Mailbox',
      legalDepartmentMailbox: 'Legal Mailbox',
    },
  },
  home: {
    menu: 'Home',
    message: `In case of questions please contact research.admin@sfu.ac.at.`,
    charts: {
      day: 'Day',
      red: 'Red',
      green: 'Green',
      yellow: 'Yellow',
      grey: 'Grey',
      blue: 'Blue',
      orange: 'Orange',
      months: {
        1: 'January',
        2: 'February',
        3: 'March',
        4: 'April',
        5: 'May',
        6: 'June',
        7: 'July',
      },
      eating: 'Eating',
      drinking: 'Drinking',
      sleeping: 'Sleeping',
      designing: 'Designing',
      coding: 'Coding',
      cycling: 'Cycling',
      running: 'Running',
      customer: 'Customer',
    },
  },

  toDo: {
    name: 'To-Do List',
    headers: {
      pendingSignedDocuments: 'Pending upload of Signed Documents',
    },
  },
  errors: {
    backToHome: 'Back to home',
    403: `Sorry, you don't have access to this page`,
    404: 'Sorry, the page you visited does not exist',
    500: 'Sorry, the server is reporting an error',
    429: 'Too many requests. Please try again later.',
    forbidden: {
      message: 'Forbidden',
    },
    validation: {
      message: 'An error occurred',
    },
    defaultErrorMessage: 'Ops, an error occurred',
    defaultWarningMessage: 'Ops, a warning occurred',
    projectNavigateStep:
      'You are not allowed to navigate to project steps in advanced',
    files: {
      noPermissions: 'You are not allowed to {0} file.',
    },
  },
  // See https://github.com/jquense/yup#using-a-custom-locale-dictionary
  /* eslint-disable */
  validation: {
    mixed: {
      default: '${path} is invalid',
      required: '${path} is required',
      oneOf: '${path} must be one of the following values: ${values}',
      notOneOf: '${path} must not be one of the following values: ${values}',
      notType: ({ path, type, value, originalValue }) => {
        return `${path} must be a ${type}`;
      },
    },
    string: {
      length: '${path} must be exactly ${length} characters',
      min: '${path} must be at least ${min} characters',
      max: '${path} must be at most ${max} characters',
      matches: '${path} must match the following: "${regex}"',
      email: '${path} must be a valid email',
      url: '${path} must be a valid URL',
      trim: '${path} must be a trimmed string',
      lowercase: '${path} must be a lowercase string',
      uppercase: '${path} must be a upper case string',
      selected: '${path} must be selected',
    },
    number: {
      min: '${path} must be greater than or equal to ${min}',
      max: '${path} must be less than or equal to ${max}',
      lessThan: '${path} must be less than ${less}',
      moreThan: '${path} must be greater than ${more}',
      notEqual: '${path} must be not equal to ${notEqual}',
      positive: '${path} must be a positive number',
      negative: '${path} must be a negative number',
      integer: '${path} must be an integer',
      invalid: '${path} must be a number',
    },
    date: {
      min: '${path} field must be later than ${min}',
      max: '${path} field must be at earlier than ${max}',
    },
    boolean: {},
    object: {
      noUnknown:
        '${path} field cannot have keys not specified in the object shape',
    },
    array: {
      min: '${path} field must have at least ${min} items',
      max: '${path} field must have less than or equal to ${max} items',
    },
  },
  /* eslint-disable */
  fileUploader: {
    upload: 'Upload',
    image: 'You must upload an image',
    size: 'File is too big. Max allowed size is {0}',
    formats: `Invalid format. Must be '{0}'.`,
  },
  importer: {
    line: 'Line',
    status: 'Status',
    pending: 'Pending',
    imported: 'Imported',
    error: 'Error',
    total: `{0} imported, {1} pending and {2} with error`,
    importedMessage: `Processed {0} of {1}.`,
    noNavigateAwayMessage:
      'Do not navigate away from this page or import will be stopped.',
    completed: {
      success: 'Import completed. All rows were successfully imported.',
      someErrors:
        'Processing completed, but some rows were unable to be imported.',
      allErrors: 'Import failed. There are no valid rows.',
    },
    form: {
      downloadTemplate: 'Download the template',
      hint: 'Click or drag the file to this area to continue',
    },
    list: {
      discardConfirm: 'Are you sure? Non-imported data will be lost.',
    },
    errors: {
      invalidFileEmpty: 'The file is empty',
      invalidFileExcel: 'Only excel (.xlsx) files are allowed',
      invalidFileUpload:
        'Invalid file. Make sure you are using the last version of the template.',
      importHashRequired: 'Import hash is required',
      importHashExistent: 'Data has already been imported',
    },
  },

  autocomplete: {
    loading: 'Loading...',
  },

  imagesViewer: {
    noImage: 'No image',
  },
};

export default en;
